import React from "react"
import Layout from "../components/layout";
import {graphql} from 'gatsby'
import Img from "gatsby-image";
import path from "path";

const imageWrapperStyle = {
   display: 'flex',
   flexDirection: 'column',
   width: '100%'
};

const imageStyle = {
  marginBottom: '15px'
};

export default ({data}) => {
   const photoOrder = data.allFile.edges[0].node.childPhotosPageDataJson.list;

   const nameToFluidPhoto = data.allMarkdownRemark.edges.reduce((acc, curr) => {
      if (curr.node.fields) {
         acc[path.basename(curr.node.fileAbsolutePath)] = curr.node.fields.image.childImageSharp.fluid;
      }
      return acc;
   }, {});

   
   const photos = photoOrder.map(order => {
      const photoName = order.name + ".md";
      const fluid = nameToFluidPhoto[photoName];
      return <Img
         key={photoName}
         alt={photoName}
         fluid={fluid}
         style={imageStyle}
      />
   });

   return (
      <Layout>
         <div style={imageWrapperStyle}>
            {photos}
         </div>
      </Layout>
   )
};


export const photosPageQuery = graphql`
    query PhotosPage {
        allMarkdownRemark {
         ...Images
        }
        allFile(filter: { name: { eq : "order" }})  {
          edges {
            node {
              childPhotosPageDataJson {
                list {
                  name
                }
              }
            }
          }
        }   
      }
`